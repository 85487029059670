import React from 'react';
import Root from '../_components/_routes/routes';
import { ThemeProvider, createTheme } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import ServiceWorkerWrapper from '../_components/_serviceworkerwrapper/serviceWorkerWrapper';
import { OpenCvProvider } from 'opencv-react';
import Message from '../_components/_shared/elements/message/main';
import { ConnectedMessageProvider } from '../_components/_shared/elements/message/MessageProvider';
import { ToastContainer } from 'react-toastify';

const ColorModeContext = React.createContext({ 
    toggleColorMode: () => {},
    mode: null

});

const ThemeModeColorContext = React.createContext({ 
    changeThemeModeColor: () => {},
});

export default function App() {

    const [cookies, setCookie]                    = useCookies(['themeMode']);
    const location                                = useLocation();
    const [mode, setMode]                         = React.useState(!cookies['themeMode'] ? 'dark' : cookies['themeMode']);


    /**
     * Dynamic Color Modes.
     */
    const [themeModePrimary, setThemeModePrimary] = React.useState({      
        main   : '#940202',
        light  : '#A93434',
        dark   : '#670101',
        contrastText: '#ffffff',
    });
    const [themeModeSecondary, setThemeModeSecondary] = React.useState({    
        main   : '#FF8000',
        light  : '#FF9933',
        dark   : '#B25900',
        contrastText: '#000000',
    });
    const [darkThemeModePrimary, setDarkThemeModePrimary] = React.useState({
        main: '#ffffff',
        light: '#F5F5F5',
        dark: '#E0E0E0',
        contrastText: '#000000',
    });
    const [darkThemeModeSecondary, setDarkThemeModeSecondary] = React.useState({
        main   : '#EF6C00',
        light  : '#F57C00',
        dark   : '#E65100',
        contrastText: '#ffffff',
    });

    const [tempStore, setTempStore] = React.useState({});

    const toggleColorMode = () => {
        console.log("switch")

        // Restore the original
        if(Object.keys(tempStore).length > 0){
            setThemeModePrimary(tempStore.pl);
            setThemeModeSecondary(tempStore.sl);
            setDarkThemeModePrimary(tempStore.pd);
            setDarkThemeModeSecondary(tempStore.sd);
            setTempStore({});
        }

        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        setCookie('themeMode', mode === 'light' ? 'dark' : 'light', { path: '/' });
    }

    const changeThemeModeColor = (template, palletType, mode) => {
        console.log("switching",palletType , template);
        if(Object.keys(tempStore).length < 1){
            setTempStore({pl: themeModePrimary, sl: themeModeSecondary, pd: darkThemeModePrimary, sd: darkThemeModeSecondary});
        }

        if(mode === 'light'){
            if(palletType === "primary"){
                setThemeModePrimary(template);
            }else{
                setThemeModeSecondary(template);
            }
        }else{
            if(palletType === "primary"){
                setDarkThemeModePrimary(template);
            }else{
                setDarkThemeModeSecondary(template);
            }
        }
    }

    const lightModePalette = {
        mode,
        primary: themeModePrimary,
        secondary: themeModeSecondary,
        grey:{
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121'
        },
        text:{
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            disabled: 'rgba(0, 0, 0, 0.38)'
        }
    }

    const darkModePalette = {
        mode,
        primary: darkThemeModePrimary,
        secondary: darkThemeModeSecondary,
        grey:{
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121'
        },
        text:{
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)',
            icon: 'rgba(255, 255, 255, 0.5)'
        }
    }

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: mode === 'light' ? lightModePalette : darkModePalette,
                typography: {
                    fontSize: 12,
                    fontFamily: [
                      '-apple-system',
                      'BlinkMacSystemFont',
                      '"Segoe UI"',
                      'Roboto',
                      '"Helvetica Neue"',
                      'Arial',
                      'sans-serif',
                      '"Apple Color Emoji"',
                      '"Segoe UI Emoji"',
                      '"Segoe UI Symbol"',
                    ].join(','),
                  },
            }),
        [mode, themeModePrimary, themeModeSecondary, darkThemeModePrimary, darkThemeModeSecondary],
    );

    React.useEffect(() => {
        // console.log(location.pathname)
        if(location.pathname !== "/join-us/contact-form"){
            const scriptToDelete = document.getElementById("tidiochat");
            // console.log(scriptToDelete)
            if (scriptToDelete) {
                document.head.removeChild(scriptToDelete);
            }
        }
    }, [location]);

    return (
        <ColorModeContext.Provider value={{toggleColorMode, palette: { mode }}}>
            <ThemeModeColorContext.Provider value={{changeThemeModeColor}}>
                <OpenCvProvider openCvVersion={"4.5.0"}>
                    <ThemeProvider theme={theme}>
                        <ToastContainer/>
                        <ServiceWorkerWrapper />
                        {/**Message Handler Globally */}
                        <ConnectedMessageProvider>
                            <Message/>
                            <Root />
                        </ConnectedMessageProvider>
                    </ThemeProvider>
                </OpenCvProvider>
            </ThemeModeColorContext.Provider>
        </ColorModeContext.Provider>
    );
}

export const switchColorMode = () => React.useContext(ColorModeContext);
export const switchLightModeTheme = () => React.useContext(ThemeModeColorContext);