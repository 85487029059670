import React from 'react';
import ContactForm from "./ContactForm";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import GoogleMapStyle from './ContactMapStyle';
import ContactCard from '../cards/contactCards';
import { Grid2 as Grid, Box, Card, CardActions, Button, Typography } from '@mui/material';

const ContactOne = () => {
    return (
        <React.Fragment>
            <Grid
                container
                alignItems={"center"}
                justifyContent="center"
                spacing={3}
            >

                <Grid>
                    <ContactCard
                        contactTitle = {"Want to hire us?"}
                        department = {"Sales"}
                        email = {"sales@remotefreedomconnect.com"}
                        phone = "+502 3048-5080"
                    />
                </Grid>
                <Grid>
                    <ContactCard
                        contactTitle = {"Applying to our Gigs & need help?"}
                        department = {"Sourcing"}
                        email = {" join@remotefreedomconnect.com"}
                        phone = "+502 3049-0446"
                    />
                </Grid>

                <Grid 
                    container
                    spacing={2}
                    sx={{
                        mt: 5
                    }}
                    display="flex"
                    justifyContent="center"
                >
                    <Grid size={{ xs: 10, md: 5, sm: 4 }}>
                        <ContactForm formStyle="contact-form-1" />
                    </Grid>
                    <Grid size={{ xs: 10, md: 5, sm: 4 }}>
                        <GoogleMapStyle />
                    </Grid>
                </Grid>


            </Grid>

        </React.Fragment>
    )
}
export default ContactOne;