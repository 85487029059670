import React from 'react'
import { MuiOtpInput } from 'mui-one-time-password-input'

const OTPInput = (props) => {
    const { otp, setOtp, handleComplete } = props;

    const handleChange = (newValue) => {
        setOtp(newValue)
    }


    function matchIsNumeric(text) {
        const isNumber = typeof parseInt(text) === 'number'
        return (isNumber) && !isNaN(Number(text))
    }

    const validateChar = (value, index) => {
        return matchIsNumeric(value)
    }

    return (
        <MuiOtpInput
            value={otp}
            onChange={handleChange}
            onComplete={handleComplete}
            length={6}
            autoFocus
            validateChar={validateChar}
        />
    )
}

export default OTPInput

