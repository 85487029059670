export function debounce(cb, delay) {

    let timeoutId;

    return function (...args) {

      if (timeoutId) {
        clearTimeout(timeoutId);
      }
  
      timeoutId = setTimeout(() => {
        cb(...args);
      }, delay);

    };
    
  }



  export function debouncePromise(cb, delay) {
    let timeoutId;
  
    return function (...args) {
      return new Promise((resolve) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
  
        timeoutId = setTimeout(() => {
          const result = cb(...args);
          resolve(result); // Resolving the result from the debounced callback
        }, delay);
      });
    };
  }