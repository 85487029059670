

// export const config = {
//     apiSocketURL : 'https://192.168.1.2:8080/',
//     apiUrl       : 'https://192.168.1.2:8080/api'
// }


export const config = {
    apiSocketURL : window.location.origin.indexOf('localhost') > -1 ?  'http://localhost:4000'     : 'https://middle-layer.azurewebsites.net',
    apiUrl       : window.location.origin.indexOf('localhost') > -1 ?  'http://localhost:4000/api' : 'https://middle-layer.azurewebsites.net/api'
}