
import { alertActions } from './alert.actions';
import { userConstants } from '../_constants/user.constants';
// const user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : "";

export const userActions = {
    login,
    logout,
    register,
    registerCorporate,
    refresh,
    request,
    failure,
    updateRequiresBiometrics
};

function updateRequiresBiometrics(requiresBiometrics) {
    return {
        type: userConstants.UPDATE_REQUIRES_BIOMETRICS,
        requiresBiometrics
    }
}

function refresh(user) {
    return { type: userConstants.LOGIN_REFRESH, user }
}

function login(user) {
    return { type: userConstants.LOGIN_SUCCESS, user }
}

function request(user){
    return { type: userConstants.LOGIN_REQUEST, user }
}

function failure(error){
    return { type: userConstants.LOGIN_FAILURE, error }
}

function logout() {
    return { type: userConstants.LOGOUT };
}

function register(user, origin) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user, origin)
            .then(
                data => { 
                    dispatch(success());
                    navigate('/login');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error.data.message.toString()));
                    dispatch(alertActions.error(error.data.message.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

function registerCorporate(user, origin) {
    return dispatch => {
        dispatch(request(user));

        userService.registerCorporate(user, origin)
            .then(
                user => { 
                    dispatch(success());
                    navigate('/login');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error.data.message.toString()));
                    dispatch(alertActions.error(error.data.message.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}

// function getAll() {
//     return dispatch => {
//         dispatch(request());

//         userService.getAll()
//             .then(
//                 users => dispatch(success(users.data)),
//                 error => dispatch(failure(error.data.message.toString()))
//             );
//     };

//     function request() { return { type: userConstants.GETALL_REQUEST } }
//     function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
// }



// function getAll(user) {


//     // Socket Connection
//     const socket   = socketIOClient(config.apiUrl, {
//         reconnectionDelayMax: 10000,
//         query: {
//         "accessToken": `${user.accessToken}`
//         }
//     });

//     return (dispatch) => {
//         dispatch(request());

//         socket.on("getallusers", users => {
//             dispatch(success(users))
//         });

//     };

//     function request() { return { type: userConstants.GETALL_REQUEST } }
//     function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
//     function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
// }


// prefixed function name with underscore because delete is a reserved word in javascript
// function _delete(id) {
//     return dispatch => {
//         dispatch(request(id));

//         userService.delete(id)
//             .then(
//                 user => dispatch(success(id)),
//                 error => dispatch(failure(id, error.data.message.toString()))
//             );
//     };

//     function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
//     function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
//     function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
// }