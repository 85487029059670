import React from 'react';
import footer from "../../data/footer/footer.json";
import { styled, useTheme, Grid, Link, List, ListItem, ListItemIcon, ListItemText, MenuItem, Paper, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { SocialIcon } from 'react-social-icons';
import Copyright from './CopyRight';
import { Link as RouterLink, MemoryRouter, useNavigate } from 'react-router-dom';
import { StaticRouter } from "react-router-dom/server";
import PropTypes from 'prop-types';


const footerIntem = footer[0];
const footerIntemOne = footer[1];


const pages = [
    { name: 'Home', route: '/' },
    // { name: 'About', route: '/about' },
    { name: 'Contact', route: '/contact' },
    // { name: 'Corp Login', route: '/corporate/login' },
    // { name: '#GetGigAlerts', route: '/join-us/contact-form' }
];

const policies = [
    { name: 'Privacy', route: '/policies/privacy' },
    { name: 'Terms of Service', route: '/policies/terms' },
    { name: 'Cookies', route: '/policies/cookiepolicy' },
    { name: 'Acceptable Use', route: '/policies/acceptable-use-policy' },

];

const LinkBehavior = React.forwardRef((props, ref) => (
    <RouterLink ref={ref} {...props} />
));

function Router(props) {
    const { children } = props;
    if (typeof window === 'undefined') {
        return <StaticRouter location="/">{children}</StaticRouter>;
    }

    return <MemoryRouter>{children}</MemoryRouter>;
}

Router.propTypes = {
    children: PropTypes.node,
};


const Item = styled(Box)(({ theme }) => ({
        // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        // padding: theme.spacing(1),
        textAlign: 'center',
        // color: "#ffffff",
  }));

const Footer = () => {
    // const [lightLogo, setLightLogo] = React.useState("https://rfcapp.blob.core.windows.net/rfc-public/logo/png/RFCLogo%403x.png?sp=r&st=2022-09-19T20:06:40Z&se=2050-09-20T04:06:40Z&spr=https&sv=2021-06-08&sr=b&sig=j7g9oq5Vtnypd7YVFGMvtga%2Be1XX9DOdIVN1RD7oiq0%3D");
    const [darkLogo, setDarkLogo] = React.useState("https://rfcapp.blob.core.windows.net/rfc-public/logo/png/icon_trimmed_transparent_white.png?sp=r&st=2022-11-02T03:35:17Z&se=2032-11-02T11:35:17Z&spr=https&sv=2021-06-08&sr=b&sig=zfZL80iwALjHXponyRO%2FNz3u3Oxh2FDkJCQ5Uv4x7U8%3D");
    const navigate = useNavigate()

    return (

        <Paper
            sx={{
                marginTop: 'auto',
                bottom: 0,
                borderRadius: "4.5rem 4.5rem 0rem 0rem",
            }}
            component="footer"
        >
            <Box
                sx={{
                    bgcolor: "#393939",
                    borderRadius: "4.5rem 4.5rem 0rem 0rem",
                    padding: 1
                }}
            >

                <Grid 
                    container
                    spacing={2}
                    direction={"column"}
                >
                    <Grid 
                        container
                        alignItems={"center"}
                        direction={"row"}
                        spacing={2}
                        item
                    >
                        
                        <Grid xs={12} md={5} lg={4} item>
                            <Stack 
                                direction="column" 
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    wordWrap: 'break-word',
                                    textAlign: "center"
                                }}
                            >
                                <img 
                                    style={{
                                        height: '50px',
                                    }} 
                                    src={darkLogo}
                                />

                                <Typography
                                    variant="h7"
                                    color="white"
                                    sx={{
                                        width: "50%"
                                    }}
                                >
                                    {footerIntem.title}
                                </Typography>
                            </Stack>
                        </Grid>

                        <Grid 
                            container 
                            xs={12} 
                            md={7} 
                            lg={8} 
                            item
                        >
                            <Grid xs={6} lg={3} item>
                            </Grid>

                            <Grid xs={6} lg={3} item>
                            </Grid>

                            <Grid xs={6} lg={3} item>
                                <Item>
                                    <Box
                                        id="Policies"
                                        sx={{ fontSize: '12px', textTransform: 'uppercase', color: "white" }}
                                    >
                                        Policies
                                    </Box>
                                    <List>
                                        {policies.map((page, index) => (
                                            <ListItem
                                                key={index}
                                            >
                                                <Link
                                                    sx={{
                                                        color: "white"
                                                    }}
                                                    to={page.route}
                                                    component={LinkBehavior}
                                                    underline="none"
                                                >
                                                    {page.name}
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Item>
                            </Grid>

                            <Grid xs={6} lg={3} item>
                                <Item>
                                    <Box
                                        id="company"
                                        sx={{ fontSize: '12px', textTransform: 'uppercase', color: "white" }}
                                    >
                                        Company
                                    </Box>
                                    <List>
                                        {pages.map((page, index) => (
                                            <ListItem
                                                key={index}
                                            >
                                                <Link
                                                    sx={{
                                                        color: "white"
                                                    }}
                                                    to={page.route}
                                                    component={LinkBehavior}
                                                    underline="none"
                                                >
                                                    {page.name}
                                                </Link>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Item>
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid
                        container
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        justifyContent="space-between"
                        alignItems="center"
                        item
                    >

                        <Grid  
                            item
                            sx={{ order: { xs: 2, sm: 1 } }}
                        >
                            <Copyright />
                        </Grid>

                        <Grid 
                            item     
                            sx={{ order: { xs: 1, sm: 2 } }}                   
                        >
                            <Stack direction={"row"} spacing={2}>
                                <SocialIcon fgColor={'#ffffff'} url="https://gt.linkedin.com/company/remote-freedom-connect" />
                                <SocialIcon fgColor={'#ffffff'} url="https://www.facebook.com/RemoteFreedomConnect/" />
                                <SocialIcon fgColor={'#ffffff'} url="https://www.instagram.com/remotefreedomconnect/?__coig_restricted=1" />
                            </Stack>
                        </Grid>

                    </Grid> 
 

                </Grid>



            </Box>
        </Paper>
    )
}

export default Footer
