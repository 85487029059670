import React, { createContext, useContext, useState } from "react";

// Create a context for the ConnectedMessage
const ConnectedMessageContext = createContext();

// Custom hook to use the ConnectedMessage context
export const useConnectedMessage = () => useContext(ConnectedMessageContext);

export const ConnectedMessageProvider = ({ children }) => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState({
        title: "",
        buttonLabel: "",
        subtitle: ""
    })

    const toggleShow = (value) => {
        console.log("Toggled", value)
        setShow(value);
    };

    return (
        <ConnectedMessageContext.Provider value={{ show, toggleShow, data, setData }}>
            {children}
        </ConnectedMessageContext.Provider>
    );
};
