const DetectText = async (cv, tesseract, src) => {
    const gray = new cv.Mat();
    const thresh = new cv.Mat();

    // Convert the image to grayscale
    cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY);

    // Apply thresholding
    cv.threshold(gray, thresh, 160, 255, cv.THRESH_BINARY_INV);

    // Convert the image to a canvas for Tesseract.js
    const canvas = document.createElement('canvas');
    canvas.width = thresh.cols;
    canvas.height = thresh.rows;
    cv.imshow(canvas, thresh);

    // Perform OCR using Tesseract.js
    let { data } = await tesseract.recognize(canvas, 'spa');

    gray.delete();
    thresh.delete();

    return data.text;
};

module.exports = { DetectText };
