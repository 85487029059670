import { publicUserConstants } from '../_constants';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';


const initialState = { 
    loggedIn: false, 
    user: {} 
}

const persistConfig = {
    storage, 
    key: "user", 
    stateReconciler: autoMergeLevel2
  };

export const publicuser = persistReducer(persistConfig, 
    (state = initialState, action) => {
    switch (action.type) {
        case publicUserConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case publicUserConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case publicUserConstants.LOGIN_REFRESH:
            return{
                loggedIn: true,
                user: action.user
            };
        case publicUserConstants.USER_UPDATE:
            return{
                loggedIn: true,
                user: action.user
            };
        case publicUserConstants.LOGIN_FAILURE:
            return {};
        case publicUserConstants.LOGOUT:
            return {};
        default:
            return state
    }
})