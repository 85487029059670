import persistReducer from 'redux-persist/es/persistReducer';
import { alertConstants } from '../_constants';
import storage from "redux-persist/lib/storage";

const persistConfig = {
    storage, 
    key: "alert", 
    whitelist: ["type", "message"]
  };

export const  alert = persistReducer(persistConfig, (state = {}, action)  =>{
    // console.log(action)
    switch (action.type) {
        case alertConstants.SUCCESS:
            return {
                type: 'success',
                message: action.message
            };
        case alertConstants.ERROR:
            return {
                type: 'error',
                message: action.message
            };
        case alertConstants.INFO:
            return {
                type: 'info',
                message: action.message
            };
        case alertConstants.WARNING:
            return {
                type: 'warnng',
                message: action.message
            };
        case alertConstants.CLEAR:
            return {};
        default:
            return state
    }
})