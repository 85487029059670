import * as React from 'react';
import { Button, Grid2 as Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
// import MuiPhoneNumber from 'material-ui-phone-number-2';
import { setPhone } from '../../../Formik/CustomFields/InputHelpers';
import { generalService } from '../../../../_services/general.services';
import { toast } from 'react-toastify';



const validationSchema = yup.object({
    personalEmail: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    phoneNumber: yup.string().min(4).required('Phone Number is required'),
    firstName: yup.string().min(1).required('First Name is required'),
    lastName: yup.string().min(1).required('Last Name is required')
});


const ContactForm = (props) => {

    // Formik Controller
    const formik = useFormik({
        initialValues: {
            personalEmail: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            message: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            // console.log(values)
            sendData(values);
        },
    });
    // Submit Handler
    // const submit = () => {
    //     if ((formik?.isValid && formik?.dirty)) {
    //         formik.handleSubmit();
    //     }
    // };
    // Login use Call Back.
    const sendData = React.useCallback(async (values) => {
        // console.log(values);
        delete values.short

        const addToMailListResponse = await generalService.getInTouch(true, values);
        formik.resetForm()
        // console.log(addToMailListResponse);
        if (addToMailListResponse.data.tracked) {
            toast.warning("You are already in line to getting contacted, we've re-notified!");
            return
        }

        toast.success("Check your inbox!", "We've emailed you.");

    }, [])


    React.useEffect(() => {
        // // Creates a Script Element.
        // const script = document.createElement("script");
        // // Create an ID for FrontEnd handling.
        // script.setAttribute("id", "tidiochat");
        // script.setAttribute("async", true);
        // // Generate a new random  here to use in corresponding Session Query API call
        // script.setAttribute("src", `//code.tidio.co/vzorjmmd4d1u6f30hf0xqhwrqq6v6fgp.js`);
        // document.head.appendChild(script);

        // return () => {
        //     const scriptToDelete = document.getElementById("tidiochat");
        //     if (scriptToDelete) {
        //         document.head.removeChild(scriptToDelete);
        //     }
        // }
    }, []);



    return (
        <>
        <Grid
            container
            spacing={1}
            display={"flex"}
            align="center"

        >

            <form onSubmit={formik.handleSubmit}>
                <Grid 
                    container 
                    spacing={2} 
                    size={{ md: 12, xs: 8}}
                >

                    <Grid size={{ md: 6, xs: 12}} key={1}>
                        <TextField
                            fullWidth
                            id="firstName"
                            name="firstName"
                            label="First Name"
                            value={formik.values.firstName}
                            onChange={formik.handleChange}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                            sx={{
                                opacity: "80%",
                                bgcolor: "primary.contrastText"
                            }}
                        />
                    </Grid>

                    <Grid size={{ md: 6, xs: 12}}  key={2}>
                        <TextField
                            fullWidth
                            id="lastName"
                            name="lastName"
                            label="Last Name"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                            sx={{
                                opacity: "80%",
                                bgcolor: "primary.contrastText"
                            }}
                        />
                    </Grid>

                    <Grid size={{ md: 6, xs: 12}} key={3}>
                        <TextField
                            fullWidth
                            id="phoneNumber"
                            name="phoneNumber"
                            label="Phone Number"
                            defaultCountry='gt'
                            variant="outlined"
                            placeholder={""}
                            regions={['america', 'central-america']}
                            value={formik.values.phoneNumber}
                            onChange={(value, country) => setPhone(value, country, formik)}
                            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                            disableCountryCode={true}
                            size="large"
                            sx={{
                                opacity: "80%",
                                bgcolor: "primary.contrastText"
                            }}
                        />
                    </Grid>
                    <Grid size={{ md: 6, xs: 12}} key={4}>
                        <TextField
                            fullWidth
                            id="personalEmail"
                            name="personalEmail"
                            label="Email"
                            value={formik.values.personalEmail}
                            onChange={formik.handleChange}
                            error={formik.touched.personalEmail && Boolean(formik.errors.personalEmail)}
                            helperText={formik.touched.personalEmail && formik.errors.personalEmail}
                            sx={{
                                opacity: "80%",
                                bgcolor: "primary.contrastText"
                            }}
                        />
                    </Grid>
                    <Grid size={{ md: 6, xs: 12}} key={5}>
                        <TextField
                            fullWidth
                            id="message"
                            name="message"
                            label="Send us a message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            error={formik.touched.message && Boolean(formik.errors.message)}
                            helperText={formik.touched.message && formik.errors.message}
                            multiline
                            rows={8}
                            maxRows={8}
                            sx={{
                                opacity: "80%",
                                bgcolor: "primary.contrastText"
                            }}
                        />
                    </Grid>
                    <Grid size={{ md: 12, xs: 12}}  key={6}>
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"                            
                            type="submit"
                        >
                            Contact Me!
                        </Button>
                    </Grid>
                </Grid>
            </form>


        </Grid>

        </>

    )
};


export default ContactForm