import React from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;


const GoogleMapStyle = () => {
  const [center, ] = React.useState({
    lat: 14.54668,
    lng: -90.54329
  });

  const [zoom, ] = React.useState(11)

    return (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBhsL6ILXsPCXo-Lc2DcKonkL0qJ9xpY2c" }}
          defaultCenter={center}
          defaultZoom={zoom}
          yesIWantToUseGoogleMapApiInternals
        >
          <AnyReactComponent
            lat={14.5474}
            lng={-90.5494}
            text="This is us"
          />
        </GoogleMapReact>
    );
  
}

export default GoogleMapStyle;