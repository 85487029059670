import { io } from 'socket.io-client';
import store from "../_redux/store";
import { userActions } from "../_redux/_actions";
import { config } from '../_helpers/apiConfig';


export const API_URL = config.apiUrl;
export const URL = config.apiSocketURL;


function getAuthToken(){
    const { authentication: { user } } = store.getState();

    if(user && user?.accessToken){
        return user?.accessToken
    }else{
        return null
    }

}

export function getClienSecret(){
    const { authentication: { user } } = store.getState();

    if(user && user?.clientID && user?.clientSecret){
        return {clientID: user?.clientID, clientSecret: user?.clientSecret}
    }else{
        return null
    }

}

function getAuthTokenPublic(){
    const { publicuser: { user } } = store.getState();

    if(user && user?.accessToken){
        return user?.accessToken
    }else{
        return null
    }

}

export function authHeader() {

    const authToken = getAuthToken();

    if (authToken) {
        return { 
            'Authorization': 'Bearer ' + authToken,
            'Content-Type': 'application/json'
        };
    } else {
        return { 
            'Content-Type': 'application/json'
        };
    }
}

export function authHeaderPublic() {

    const authToken = getAuthTokenPublic();

    if (authToken) {
        return { 
            'Authorization': 'Bearer ' + authToken,
            'Content-Type': 'application/json'
        };
    } else {
        return { 
            'Content-Type': 'application/json'
        };
    }
}

export const socket = io( `${URL}/`, {
    reconnectionDelayMax: 10000,
    autoConnect: true,
    auth:(cb) => {
        cb({
          token: getAuthToken()
        });    
    }
})


export const publicSocket = io( `${URL}/`, {
    reconnectionDelayMax: 10000,
    autoConnect: true,
    auth:(cb) => {
        cb({
          token: getAuthTokenPublic() || "PUBLIC_TOKEN"
        });    
    }
})


export async function handleResponse(response) {
    try{
        const text = await response.text();
        const data = text ? JSON.parse(text) : {};

        // Handle JWT expiration case
        if (data.message === "jwt expired" && response.status === 401) {
            // toastr.warning("Your Session has expired", "Logging you out.")
            store.dispatch(userActions.logout());
            // Optionally, redirect user to expired session page
            // window.location.href = `${window.location.origin}/expired`;
        }

        // Handle non-OK responses (e.g., 4xx or 5xx errors)
        if (!response.ok) {
            const errorMessage = data.data?.message || response.statusText;
            throw { status: response.status, data: { message: errorMessage } };
        }

        return data;  // Return the parsed data

    }catch(err){
        throw err;  // Re-throw error after logging
    }

}
