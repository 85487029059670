import * as React from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Stack,
  useScrollTrigger,
  ListItemText,
  ListItemIcon,
  useTheme
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { switchColorMode } from '../../../../App/App';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { useNavigate, useLocation } from 'react-router-dom';
import { borderBottom } from '@mui/system';
import { solutions } from "../../../../../src/Pages/Public/CaseStudies/CaseStudiesHome/main"
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import RegisterCompany from '../../components/registerCompany/RegisterCompany';

const pages = [
  { name: 'Home', route: '/' },
  // { name: 'About', route: '/about' },
  { name: 'Contact', route: '/contact' },
  // { name: 'Corp Login', route: '/corporate/login' },
  // { name: '#GetGigAlerts', route: '/join-us/contact-form' }
];

const Header = ({ btnStyle, HeaderSTyle }) => {

  const { toggleColorMode } = switchColorMode();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [lightLogo, setLightLogo] = React.useState("https://rfcapp.blob.core.windows.net/rfc-public/logo/png/icon_trimmed_transparent_color1.png?sp=r&st=2022-11-02T03:36:12Z&se=2032-11-02T11:36:12Z&spr=https&sv=2021-06-08&sr=b&sig=9xvXQrp33vNy%2BeavHmVbOZSTo4ObNn4nMkCrQaZW0Iw%3D");
  const [darkLogo, setDarkLogo] = React.useState("https://rfcapp.blob.core.windows.net/rfc-public/logo/png/icon_trimmed_transparent_white.png?sp=r&st=2022-11-02T03:35:17Z&se=2032-11-02T11:35:17Z&spr=https&sv=2021-06-08&sr=b&sig=zfZL80iwALjHXponyRO%2FNz3u3Oxh2FDkJCQ5Uv4x7U8%3D");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const location = useLocation();

  const theme = useTheme();

  const navigate = useNavigate();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <AppBar 
      position="fixed" 
      sx={{ 
          bgcolor: trigger ? "primary.contrastText" :  "#000000",
          // opacity: 0.7
        }} 
      >

      <Toolbar>
        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
        <Container>

          <Stack direction={"row"} justifyContent={"center"} alignItems={"center"}>

            {/** Mobile */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="primary"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.name} href={page.route} onMouseDown={(e) => { e.preventDefault() }} onClick={(e) => { navigate(page.route) }}>
                    <Typography color={location.pathname === page.route ? "secondary" : "text"} textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
                <MenuItem
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ color: location.pathname.indexOf('case-study') > -1 ? "red" : "primary", fontWeight: "strong" }}
                >
                  <Typography textAlign="center">Case Study</Typography>

                </MenuItem>
                <MenuItem
                  id="basic-button"
                >
                  {/* <Typography textAlign="center">Case Study</Typography> */}
                  <RegisterCompany/>

                </MenuItem>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  PaperProps={{
                    style: {
                      width: "500px",
                      height: "200px",
                      maxHeight: 'unset',
                      maxWidth: 'unset',
                    }
                  }}
                >
                  {solutions.map((s, index) => {
                    const { icon: Icon } = s
                    return (
                      <MenuItem key={index} onClick={() => { navigate(`case-study/${s.StudyID}`) }}>
                        <ListItemIcon>
                          <Icon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{s.name}</ListItemText>
                      </MenuItem>
                    )
                  })

                  }
                </Menu>
              </Menu>
            </Box>
            {/** Mobile END */}

            {/**LOGO */}
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'flex', md: 'flex' } }}>
              <img
                style={{
                  height: '50px',
                  width: 'auto'
                }}
                src={theme.palette.mode === 'dark' ? darkLogo : lightLogo}
              />
            </Box>
            {/**LOGO END*/}


            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

              {pages.map((page) => (
                <Button
                  // color="red"
                  // color={location.pathname === page.route ? "secondary" : "primary" }
                  onMouseDown={(e) => { e.preventDefault() }}
                  key={page.name}
                  onClick={(e) => { e.preventDefault(); navigate(page.route) }}
                  sx={{ color: location.pathname === page.route ? "red" : "primary", fontWeight: "strong" }}
                >
                  {page.name}
                </Button>

              ))}

              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ color: location.pathname.indexOf('case-study') > -1 ? "red" : "primary", fontWeight: "strong" }}
              >
                Case Study
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                  style: {
                    width: "500px",
                    height: "200px",
                    maxHeight: 'unset',
                    maxWidth: 'unset',
                  }
                }}
              >
                {solutions.map((s, index) => {
                  const { icon: Icon } = s
                  return (
                    <MenuItem key={index} onClick={() => { navigate(`case-study/${s.StudyID}`) }}>
                      <ListItemIcon>
                        <Icon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>{s.name}</ListItemText>
                    </MenuItem>
                  )
                })

                }
              </Menu>

            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title={`Toggle ${theme.palette.mode === 'dark' ? 'light' : 'dark'} Mode`}>
                <IconButton sx={{ mr: 2 }} color="primary" onMouseDown={(e) => { e.preventDefault() }} onClick={(e) => { e.preventDefault(); toggleColorMode() }}>
                  {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon color="primary" />}
                </IconButton>
              </Tooltip>
            </Box>

            <Box sx={{ flexGrow: 0,  display: { xs: 'none', md: 'flex' } }}>
                <RegisterCompany/>
            </Box>

          </Stack>

        </Container>

      </Toolbar>
    </AppBar>
  )
}
export default Header;