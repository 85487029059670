import React from 'react';
import Lottie from 'react-lottie-player'
import animationData from './LottieLoading.json'

export default function LottieLoading(props) {

    return (
        <Lottie
            loop
            animationData={animationData}
            play
            style={{ width: props.width ?? 100, height: props.height ?? 100 }}
        />
    );
}
