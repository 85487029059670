import { authHeader, serviceAppAuth } from '../_helpers';
import { handleResponse } from './services.index';
 

import { config } from '../_helpers/apiConfig';
 


export const generalService = {
    getBlobSasUri,
    getBlobSasUriPublic,
    resetPasswordRequest,
    documentCheckRequest,
    resetPassword,
    markRequestKeyCompleted,
    joinUsMailingList,
    getInTouch,
    identityVerificationApproval,
    resetIdentityVerification,
    validateDomain,
    sendOTP,
    verifyOTP,
    validateAddress,
    checkTXTRecord,
    createCompany
};

async function createCompany(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/database/companies/create`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function validateAddress(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/services/localization/gt/address-validator-intern`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

/**
 * 
 * @param {*} data Should pass an attribute domain in the JSON Object.
 * @returns 
 */
async function sendOTP(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/authentication/otp/generate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

/**
 * 
 * @param {*} data Should pass an attribute domain in the JSON Object.
 * @returns 
 */
async function verifyOTP(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/authentication/otp/validate`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

/**
 * 
 * @param {*} data Should pass an attribute domain in the JSON Object.
 * @returns 
 */
async function validateDomain(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/company/domain-verification`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

/**
 * 
 * @param {*} data Should pass the attributes { host, domain, value } in the JSON Object.
 * @returns 
 */
async function checkTXTRecord(data) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/check/dns/txt`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function getBlobSasUri(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/storage/generateblobsasuri`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function getBlobSasUriPublic(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/storage/generateblobsasuri`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function resetPasswordRequest(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/reset/password/request`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function documentCheckRequest() {

    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return await fetch(`${config.apiUrl}/general/document/verification/request`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function resetPassword(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/reset/password`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function markRequestKeyCompleted(data, token) {
    const requestOptions = {
        method: 'POST',
        headers: token ?
        { 
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
        :
        authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/request/key/complete`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function identityVerificationApproval(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/approve/identityverification`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
    });
}

async function resetIdentityVerification(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/reset/identityverification`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
    });
}


async function joinUsMailingList(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/join-us/contact`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

async function getInTouch(serviceApp, data) {
    const requestOptions = {
        method: 'POST',
        headers: serviceApp ? await serviceAppAuth() : authHeader(),
        body: JSON.stringify(data)
    };
    return await fetch(`${config.apiUrl}/general/sales/contact`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}