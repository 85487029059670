/**
 *
 * @param {*} cv OpenCV instance
 * @param {*} src Video Src translated into a Mat & processed as a Frame.
 * @param {*} canvas Optional, used to see the Outcome of the Video
 * @param {*} glareThreshold default 2800
 * @returns Boolean if there is or isn't blur.
 */
const GlareDetection = (cv, src, canvas = null, glareThreshold = 4893) => {
    // Create a Mat object for grayscale image
    let glareDetect = new cv.Mat();

    // Convert the image to grayscale
    cv.cvtColor(src, glareDetect, cv.COLOR_BGR2GRAY, 0);

    // Apply a binary threshold to detect glare areas
    cv.threshold(glareDetect, glareDetect, 250, 255, cv.THRESH_BINARY);

    // Count the number of non-zero pixels (glare spots)
    let no = cv.countNonZero(glareDetect);

    // Display the glare detection result on the canvas if provided
    if (canvas) {
        cv.imshow(canvas, glareDetect);
    }

    // Cleanup Mat objects
    glareDetect.delete();
    // Return true if the number of glare spots exceeds the glare threshold
    return no >= glareThreshold;
};

module.exports = { GlareDetection };
