/**
 *
 * @param {*} cv OpenCV instance
 * @param {*} src Video Src translated into a Mat & processed as a Frame.
 * @param {*} canvas Optional, used to see the Outcome of the Video
 * @param {*} threshold Default set to 100
 * @returns Integer Measure of the current focus, for IDs, it is recommended focus is over 350, for face Ids, focus over 30
 */
const BlurDetection = (cv, src, canvas = null, threshold = 350) => {
    // Open CV
    let gray = new cv.Mat(); // Gray scale Mat
    let laplacian = new cv.Mat(); // Laplacian Mat

    // Convert to Gray Scale
    cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY);

    // Detect Edges using Laplacian
    cv.Laplacian(gray, laplacian, cv.CV_64F); // Using CV_64F for better accuracy

    // Calculate the variance of the Laplacian (focus measure)
    const stddev = new cv.Mat();
    const mean = new cv.Mat();

    cv.meanStdDev(laplacian, mean, stddev);
    const focusMeasure = stddev.doubleAt(0, 0); // Getting the standard deviation

    // Cleanup Mats
    gray.delete();
    laplacian.delete();
    stddev.delete();
    mean.delete();

    // Display the image on canvas if provided
    if (canvas) {
        cv.imshow(canvas, gray);
    }

    // Return true if focus measure is greater than the threshold
    return focusMeasure * focusMeasure < threshold;
};

module.exports = { BlurDetection };
