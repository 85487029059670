import {configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';
import rootReducer from './_reducers';
import {persistStore} from "redux-persist";
import {reduxBatch} from "@manaflair/redux-batch";


const sagaMiddleware = createSagaMiddleware();

const middleware = (getDefaultMiddleware) => {
  return getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  }).concat(sagaMiddleware);
}

const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV !== "production",
    enhancers: [reduxBatch]
  });

export const persistor = persistStore(store);
export default store;