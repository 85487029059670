import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { publicuser } from './publicuser.reducer';
import { registration } from './registration.reducer';
import { alert } from './alert.reducer';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from "redux-persist/lib/storage";


const rootPersistConfig = {
    key: 'root',
    storage: storage,
    blacklist: []
  };

const reducers = combineReducers({
    authentication,
    registration,
    publicuser,
    alert,
});

const rootReducer = persistReducer({
    key: 'root',
    storage
}, reducers)

export default persistReducer(rootPersistConfig, rootReducer);