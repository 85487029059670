import { config } from "./apiConfig";

export function authHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.accessToken) {
        return { 
            'Authorization': 'Bearer ' + user.accessToken,
            'Content-Type': 'application/json'
        };
    } else {
        return {};
    }
}


export async function serviceAppAuth(){

    const data = {
        "clientID": "2B4D6251655468576D5A7134743777217A25432A462D4A404E635266556A586E"/*process.env.PUBLIC_CLIENTID*/,
        "clientSecret": "4528472B4B6250655368566D597133743677397A24432646294A404D63516654" /*process.env.PUBLIC_CLIENTSECRET*/
    }


    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    };

    try{
        const requestServiceAccessToken = await fetch(`${config.apiUrl}/authentication/service`, requestOptions);
        const data = await handleResponse(requestServiceAccessToken);
        return { 
            'Authorization': `${data.data.type} ` + data.data.accessToken,
            'Content-Type': 'application/json'
        };
    }catch(err){
        console.log("Error", err)
    }

}


function handleResponse(response) {
    // console.log("This is the response",response)
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        // console.log(data)
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                // location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}