import React from "react"

// import * as tf from '@tensorflow/tfjs';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Avatar, Box, Button, Card, CardActions, CardHeader, CardMedia, CircularProgress, IconButton, useTheme, Stack, Typography, CardContent } from "@mui/material"
import _logistic from "../../../../../_helpers/tensorflow/_logistic";
import processOldModel from "../../../../../_helpers/tensorflow/objectdetection/oldmodel.processing";


const cocossd = window.cocoSsd;

const TARGET_CLASSES = {
    0: "BackPersonalDocumentGT",
    1: "FrontPersonalDocumentGT",
    2: "PassportGT",
  };



const VideoStream = (props) => {
    
    const { liveVideoRef, cameraRef, predicted, predictedData } = props;

    // Control the Canvas Image Overaly.
    const canvasRef                   = React.useRef(null);

    const [permission, setPermission] = React.useState(false);
    const [error, setError]           = React.useState({});

    let successColor = "#4BB543";
    let neutralColor = "#d9d9d9";

    const svg       = props.getSVG;
    const stepName  = props.name;
    const subHeader = props.subHeader;
    const stepId    = props.id;

    const drawPalceHolderImage = (color) => {

        const ctx       = canvasRef.current.getContext("2d");
        const canvas    = canvasRef.current;
        var image       = new Image();

        // image.src="./media/identityimages/id.svg";
        image.src = `data:image/svg+xml;base64,${window.btoa(svg(color))}`;
        image.onload=function(){
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        };

    }

    const getCameraPermission = async () => {
        setError({});
        //get video permissions and then stream the result media stream to the videoSrc variable
        if ("MediaRecorder" in window) {
     
                const videoConstraints = {
                    video: true,
                };

                await navigator.mediaDevices.getUserMedia(
                    videoConstraints
                ).then((videoStream) => {


                    setPermission(true);
    
                    //set videostream to live feed player
                    liveVideoRef.current.srcObject = videoStream;
    
                    const track = liveVideoRef.current?.srcObject.getVideoTracks()[0];
                    const settings = track.getSettings();
    
                    canvasRef.current.width  = settings.width;
                    canvasRef.current.height = settings.height;
    
                    drawPalceHolderImage(neutralColor);

                }).catch((err) => {
                    // alert(err.message);
                    setError({
                        error: true,
                        errorMessage: err.message
                    })
                
                })
        } else {
            toastr.error("Media no se puede grabar desde tu maquina.");
        }
    };


    // Open Camera on Entering.

    React.useEffect(() => {

        getCameraPermission();

        return () => {
            liveVideoRef.current?.srcObject.getTracks().forEach(function (track) {
                track.stop();
            });
        }

    }, [])

    
    const handleCapturedImage = (e) => {
        console.log(e)
    };


    const takeCameraFrame = () => {

        const canvas   = cameraRef.current;
        const context  = canvas.getContext("2d");
        // context.drawImage(image, left, top, width, height);
        const track    = liveVideoRef.current?.srcObject.getVideoTracks()[0];
        const settings = track.getSettings();

        canvas.style.width = "50%";
        canvas.style.height = "50%";
        context.drawImage(liveVideoRef.current, 0, 0, settings.width, settings.height);
        cameraRef.current.toBlob(handleCapturedImage);
    }



    React.useEffect(() => {
        if(!!predicted){
            takeCameraFrame();
            liveVideoRef.current?.srcObject.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    },[predicted])


    // const theme = useTheme();
    return (
        <Card
            elevation={5}
            //**Control the Size of the Card on different Breakpoints */
            sx={(theme) => ({
                width: "30vw",
                [theme.breakpoints.down("lg")]: {
                    width: "60vw",
                },
                [theme.breakpoints.down("md")]: {
                    width: "80vw",
                },
                [theme.breakpoints.down("sm")]: {
                    width: "80vw",
                },
                [theme.breakpoints.down("xs")]: {
                    width: "80vw",
                },
            })}
        >
            <CardHeader
                // avatar={
                //     <Avatar sx={{ bgcolor: "red" }} aria-label="recipe">
                //         R
                //     </Avatar>
                // }
                action={
                    <IconButton aria-label="settings">

                    </IconButton>
                }
                title={stepName}
                subheader={`${subHeader} - ${predictedData?.name}: ${predictedData?.percentage}%`}
            />

            <Box
                sx={{
                    position: 'relative'
                }}
            >
                <CardMedia
                    component={"video"}
                    ref={liveVideoRef}
                    autoPlay
                />
                <Box
                    component={"canvas"}
                    ref={canvasRef}
                    sx={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        margin: "auto",
                        zindex: 8,
                        height: "80%",
                        fill: "green"
                    }}
                />


            </Box>

            <CardContent>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                >
                    <canvas
                        component={"canvas"}
                        ref={cameraRef}
                        height={480}
                        width={640}        
                    />
                </Box>

            </CardContent>



            <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
            >
                <CardActions>

                    {!permission ?

                        <Stack direction={"column"} spacing={2} alignItems={"center"}>
                            
                            {error.error ?
                                <>
                                    <Typography variant="subtitle1" >
                                        {
                                            error.errorMessage
                                        }
                                    </Typography>
                                    <Typography variant="subtitle2" width={"50%"} textAlign={"center"}>
                                        You may need to allow your Camera Access in your browser Settings.
                                    </Typography>
                                    <Button onClick={getCameraPermission} variant="outlined">
                                        Try Again
                                    </Button>
                                </>

                                :
                                <CircularProgress/>
                            }
                        </Stack>
                        :

                        <IconButton
                            onClick={takeCameraFrame}
                            variant="outlined"
                            size="large"
                        >
                            <FiberManualRecordIcon sx={{ fontSize: 50 }} />
                        </IconButton>


                    }

                </CardActions>
            </Box>
        </Card>
    )
}

// offering Passport & Document Reading  : 50 Cents Per Lecture
// offering Biometric Enrollment         : 100 Cents
// offering RENAP                        : 250 Cents
// offering Reverification               : 10 Cents
// offering Address validation           : 20 Cents

const CustomIdentityVerification = () => {

    const types = ["FrontPersonalDocumentGT"]


    const liveVideoRef                = React.useRef(null);
    // Controls the Images created when capturing.
    const cameraRef                   = React.useRef(null);
    const [predicted, setPredicted]   = React.useState(false);
    const [predictedData, setPredictedData] = React.useState({
        name: "-",
        percentage: "-"
    });


    const idSVG = React.useCallback((color) => {

        const idSVG = `
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="640"
            zoomAndPan="magnify" viewBox="0 0 480 359.999987" height="480"
            preserveAspectRatio="xMidYMid meet" version="1.0">
            <path fill="${color}"
                d="M 438.863281 36 L 41.136719 36 C 18.449219 36 0 54.449219 0 77.136719 L 0 282.847656 C 0 305.550781 18.449219 324 41.136719 324 L 438.847656 324 C 461.550781 324 480 305.550781 480 282.863281 L 480 77.136719 C 480 54.449219 461.550781 36 438.863281 36 Z M 41.136719 52 L 438.847656 52 C 452.71875 52 464 63.28125 464 77.136719 L 464 268.289062 L 16 268.289062 L 16 77.136719 C 16 63.28125 27.28125 52 41.136719 52 Z M 438.863281 308 L 41.136719 308 C 27.761719 308 16.894531 297.472656 16.144531 284.289062 L 463.855469 284.289062 C 463.105469 297.472656 452.222656 308 438.863281 308 Z M 438.863281 308 "
                fill-opacity="1" fill-rule="nonzero" />
            <path fill="${color}"
                d="M 56.433594 238.863281 L 157.550781 238.863281 C 171.183594 238.863281 182.273438 227.777344 182.273438 214.144531 L 182.273438 113.007812 C 182.273438 99.375 171.183594 88.304688 157.550781 88.304688 L 56.433594 88.304688 C 42.800781 88.304688 31.710938 99.390625 31.710938 113.007812 L 31.710938 214.144531 C 31.710938 227.777344 42.800781 238.863281 56.433594 238.863281 Z M 74.816406 222.863281 L 74.816406 199.679688 C 74.816406 181.9375 89.246094 167.503906 106.992188 167.503906 C 124.734375 167.503906 139.167969 181.9375 139.167969 199.679688 L 139.167969 222.863281 Z M 93.792969 138.289062 C 93.792969 130.992188 99.726562 125.070312 107.007812 125.070312 C 114.289062 125.070312 120.222656 131.007812 120.222656 138.289062 C 120.222656 145.566406 114.289062 151.503906 107.007812 151.503906 C 99.726562 151.503906 93.792969 145.566406 93.792969 138.289062 Z M 47.710938 112.992188 C 47.710938 108.191406 51.632812 104.289062 56.433594 104.289062 L 157.566406 104.289062 C 162.367188 104.289062 166.289062 108.191406 166.289062 112.992188 L 166.289062 214.128906 C 166.289062 218.929688 162.367188 222.847656 157.566406 222.847656 L 155.183594 222.847656 L 155.183594 199.679688 C 155.183594 181.136719 144.527344 165.183594 129.136719 157.136719 C 133.488281 152.03125 136.222656 145.503906 136.222656 138.289062 C 136.222656 122.175781 123.121094 109.070312 107.007812 109.070312 C 90.894531 109.070312 77.792969 122.175781 77.792969 138.289062 C 77.792969 145.503906 80.527344 152.03125 84.878906 157.136719 C 69.488281 165.183594 58.832031 181.136719 58.832031 199.679688 L 58.832031 222.863281 L 56.433594 222.863281 C 51.632812 222.863281 47.710938 218.945312 47.710938 214.144531 Z M 47.710938 112.992188 "
                fill-opacity="1" fill-rule="nonzero" />
            <path fill="${color}"
                d="M 218 188 L 432.289062 188 C 436.703125 188 440.289062 184.414062 440.289062 180 C 440.289062 175.585938 436.703125 172 432.289062 172 L 218 172 C 213.585938 172 210 175.585938 210 180 C 210 184.417969 213.585938 188 218 188 Z M 218 188 "
                fill-opacity="1" fill-rule="nonzero" />
            <path fill="${color}"
                d="M 218 228.574219 L 360.289062 228.574219 C 364.703125 228.574219 368.289062 224.992188 368.289062 220.574219 C 368.289062 216.160156 364.703125 212.574219 360.289062 212.574219 L 218 212.574219 C 213.585938 212.574219 210 216.160156 210 220.574219 C 210 224.992188 213.585938 228.574219 218 228.574219 Z M 218 228.574219 "
                fill-opacity="1" fill-rule="nonzero" />
            <path fill="${color}"
                d="M 218 147.425781 L 360.289062 147.425781 C 364.703125 147.425781 368.289062 143.839844 368.289062 139.425781 C 368.289062 135.007812 364.703125 131.425781 360.289062 131.425781 L 218 131.425781 C 213.585938 131.425781 210 135.007812 210 139.425781 C 210 143.839844 213.585938 147.425781 218 147.425781 Z M 218 147.425781 "
                fill-opacity="1" fill-rule="nonzero" />
            <path fill="${color}"
                d="M 218 106.863281 L 392 106.863281 C 396.417969 106.863281 400 103.28125 400 98.863281 C 400 94.449219 396.414062 90.863281 392 90.863281 L 218 90.863281 C 213.585938 90.863281 210 94.449219 210 98.863281 C 210 103.28125 213.585938 106.863281 218 106.863281 Z M 218 106.863281 "
                fill-opacity="1" fill-rule="nonzero" />
            </svg>
        `

        return idSVG


    },[]);



    const NEW_OD_OUTPUT_TENSORS = ['detected_boxes', 'detected_scores', 'detected_classes'];


    const detect = async (model) => {

        const is_new_od_model    = model.inputs.length == 3;
        // Pre-process the image

        let imageFeatures = tf.tidy(() => {
            const input_size     = model.inputs[0].shape[1];
            let image            = tf.browser.fromPixels(liveVideoRef.current, 3);
            const resizedImage   = tf.image.resizeBilinear(image.expandDims().toFloat(), [input_size, input_size]);
            return is_new_od_model ? resizedImage : resizedImage.reverse(-1); // RGB->BGR for old models
        });


        // console.log(imageFeatures)


        // console.log(image)
        const outputs        = model.execute(imageFeatures, is_new_od_model ? NEW_OD_OUTPUT_TENSORS : null);
        // const arrays         = !Array.isArray(outputs) ? outputs.array() : Promise.all(outputs.map(t => t.array()));
        let predictions      =  outputs.arraySync();

        // Post processing for old models.
        if (predictions.length != 3) {
            predictions = processOldModel(predictions);
        }


        console.log(predictions)
        // Filter of looking documents.
        const boxes   = [];
        const scores  = [];
        const classes = [];

        predictions[0].map((v, n) => {
            if(types.includes(TARGET_CLASSES[predictions[2][n]])){
                boxes.push(predictions[0][n])
                scores.push(predictions[1][n])
                classes.push(predictions[2][n])
            }
        });

        const filteredPredictions = [boxes, scores, classes]

        console.log(filteredPredictions)
        setPredictedData({
            name: TARGET_CLASSES[filteredPredictions[2][0]],
            percentage: Math.round(parseFloat(filteredPredictions[1][0]) * 100) 
        })
 
        filteredPredictions[0].map((v, n) => {

            console.log(TARGET_CLASSES[filteredPredictions[2][n]]  + ': ' 
            + Math.round(parseFloat(filteredPredictions[1][n]) * 100) 
            + '%');


            
            if (filteredPredictions[1][n] > 0.90) {
                console.log("Final Prediction ", TARGET_CLASSES[filteredPredictions[2][n]]);

                setPredicted(true);
                clearInterval(intervalRef.current);
            }
        })

        outputs.dispose();

    }

    const intervalRef = React.useRef(null);

    // If there is a live feed and a model, run Common Objects.
    const runCommonObjectsInContext = async (model) => {
        intervalRef.current = setInterval(() => {
            if(!!liveVideoRef.current?.srcObject && !!model){
                detect(model);
            }
        },[10]);
    }


    function showProgress(percentage) {
        var pct = Math.floor(percentage*100.0);
        console.log(`${pct}% loaded`);
    }

    const loadModel = async () => {
        const model = await tf.loadGraphModel("https://vca.blob.core.windows.net/model/v1/model.json", {onProgress: showProgress});
        runCommonObjectsInContext(model);
    }


    React.useEffect(() => {

        // Load CocoSsd
        loadModel();


        return () => {
            clearInterval(intervalRef.current);
            liveVideoRef.current = null;
            cameraRef.current = null;
        }

    },[])




    const steps = [
        {
            id: 1,
            name: "Document Verification",
            subHeader: "Please scan the front of the ID",
            getSVG: idSVG,
        }
    ]




    return (
        <Box
            sx={{ flexGrow: 1, mt: 10 }}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}

        >

            <VideoStream  
                {...steps[0]} 
                liveVideoRef={liveVideoRef} 
                cameraRef={cameraRef} 
                predicted={predicted}
                predictedData={predictedData}
            />

        </Box>
    )


}



export default CustomIdentityVerification