/**
 *
 * @param {*} cv
 * @param {*} src
 * @param {*} videoWidth
 * @param {*} videoHeight
 * @param {*} passport
 * @param {*} canvas
 * @returns
 */
const CalculateBoundingArea = (cv, src, videoWidth, videoHeight, passport) => {

    let w = videoWidth;
    let aspectRatio = w / videoHeight;
    // let idAspectRatio = 1.586; // 1.586 is the aspect ratio for an Id
    let idAspectRatio = 85.6 / 53.98; // ~1.5857

    if (passport) {
        idAspectRatio = 1.42; // 1.42 is the aspect ratio for a Passport
    }

    let pt1 = { x: 0, y: 0 };
    let pt2 = { x: 0, y: 0 };

    // Check if the aspect ratio is landscape
    if (aspectRatio > 1) {
        // Limit the ROI width to a maximum of 600
        w = Math.min(w, 1000);

        // The ROI is a rectangle that should match
        // 95% of the width and
        // the height is calculated based on the aspect ratio
        let roi_w = w * 0.9;
        let roi_h = roi_w / idAspectRatio;

        // Calculate the coordinates to center the ROI within the video frame
        let x_center = videoWidth / 2;
        let y_center = videoHeight / 2;

        let roi_x1 = x_center - roi_w / 2;
        let roi_y1 = y_center - roi_h / 2;
        let roi_x2 = x_center + roi_w / 2;
        let roi_y2 = y_center + roi_h / 2;

        pt1 = { x: roi_x1, y: roi_y1 };
        pt2 = { x: roi_x2, y: roi_y2 };
    } else {
        // The ROI is a rectangle that should match
        // 95% of the width and
        // the height is calculated based on the aspect ratio
        let roi_w = w * 0.9;
        let roi_h = roi_w / idAspectRatio;

        // Calculate the coordinates to center the ROI horizontally and add a small margin at the top
        let margin_top = 100;

        let roi_x1 = (videoWidth - roi_w) / 2;
        let roi_y1 = margin_top;
        let roi_x2 = roi_x1 + roi_w;
        let roi_y2 = roi_y1 + roi_h;

        pt1 = { x: roi_x1, y: roi_y1 };
        pt2 = { x: roi_x2, y: roi_y2 };
    }

    return { pt1, pt2 };
};

module.exports = { CalculateBoundingArea };
