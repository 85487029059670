import React from 'react';
import Lottie from 'react-lottie-player'
import animationData from './LottieCamera.json'

export default function LottieCamera(props) {
    return (
        <Lottie
            loop
            animationData={animationData}
            play
            style={{ width: props.width ?? 100, height: props.height ?? 100 }}
        />
    );
}

