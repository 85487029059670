import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import LensTwoToneIcon from '@mui/icons-material/LensTwoTone';
import { Box, Button, Card, CardMedia, Grid2 as Grid, Typography, Stack, CardContent, IconButton } from '@mui/material';
import { useOpenCv } from 'opencv-react';
import React from 'react';
import Tesseract from 'tesseract.js';
import { BlurDetection } from '../../_helpers/openCV/BlurDetection';
import { CalculateBoundingArea } from '../../_helpers/openCV/CalculateBoundingArea';
import { DetectText } from '../../_helpers/openCV/DetectText';
import { GlareDetection } from '../../_helpers/openCV/GlareDetection';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { drawText } from '../../_helpers/tensorflow/landmarkdetection/utils';

const DocumentCheckCamera = (props) => {
    const {
        stopFlag,
        imageData,
        setImageData,
        imageText,
        setImageText,
        loadingChildComponent,
        failedCameraAccessChildComponent,
        noAvailableCameraChildComponent,
        gettingCameraPermissionChild,
        loading,
        setLoading,
        continueCallbackFunction,
    } = props;

    const [failedCameraAccess, setFailedCameraAccess] = React.useState(false);
    const [noAvailableCamera, setNoAvailableCamera] = React.useState(false);
    const [gettingCameraPermission, setGettingCameraPermission] = React.useState(true);
    const [cameraType, setCameraType] = React.useState('user');
    const [imageErrors, setImageErrors] = React.useState([]);

    const [checkingIsValidImage, setCheckingIsValidImage] = React.useState(false);
    const [isValidImage, setIsValidImage] = React.useState(false);

    const canvasRef = React.useRef(null);
    const liveVideoRef = React.useRef(null);
    const intervalRef = React.useRef(null);
    const stream = React.useRef(null);

    const { cv, loaded: openCvLoaded } = useOpenCv();

    const addLabels = (srcRef, isBlurred, hasGlare, pt1) => {
        // Define colors as grayscale intensities (0 - 255)
        let colorGreen = new cv.Scalar(200);  // Light gray for "No" (formerly green)
        let colorRed = new cv.Scalar(50);     // Darker gray for "Yes" (formerly red)
        let thickness = 2; // Thickness of the text

        let font = cv.FONT_HERSHEY_SIMPLEX;

        // Choose the appropriate gray intensity based on the condition
        const colorText = isBlurred || hasGlare ? colorRed : colorGreen;

        // Draw text with grayscale color intensity
        cv.putText(srcRef, `Has Glare: ${hasGlare ? "Yes" : "No" } | Has Blur: ${isBlurred ? "Yes" : "No"}`, 
            new cv.Point(pt1.x + 10, pt1.y + 20), font, 0.6, colorText, thickness);
    }

    const handleVideoLoaded = () => {
        const video = liveVideoRef.current;
    
        if (video && video.videoWidth > 0 && video.videoHeight > 0) {
            intervalRef.current = setInterval(() => {
                try {
                    detect();
                } catch (error) {
                    clearInterval(intervalRef.current);
                }
            }, 1000 / 30); // assuming 30fps

        } else {
            console.error('Video dimensions are invalid.');
        }
    };

    const alerts = [
        {
            id: 1,
            message: "Too much blur",
            error: true
        },
        {
            id: 2,
            message: "Too much glare",
            error: true
        },
        {
            id: 3,
            message: "Too much glare and blur",
            error: true
        },
        {
            id: 4,
            message: "All good",
            error: false
        }
    ]

    const message = React.useRef({ id: 0, message: "Loading..." });

    const setMessage = (id) => {
        const messageFound = alerts.find((a) => a.id === id);
        message.current = messageFound

        return messageFound;
    }

    const detect = async () => {

        if (!liveVideoRef.current) {
            return;
        }

        // Check if video is ready before capturing frame
        const video = liveVideoRef.current;
        if (!video || video.videoWidth === 0 || video.videoHeight === 0) {
            console.error("Video dimensions are not valid.");
            return;
        }

        let videoWidth = video.videoWidth;
        let videoHeight = video.videoHeight;

        video.width = videoWidth;
        video.height = videoHeight;

        // Open CV
        let srcRef = new cv.Mat(videoHeight, videoWidth, cv.CV_8UC4);

        // Take Frames from Video.
        let capRef = new cv.VideoCapture(document.getElementById('documentVideo'));
        capRef.read(srcRef);

        // Calculate outer ROI and inner ROI
        const { pt1, pt2 } = CalculateBoundingArea(cv, srcRef, videoWidth, videoHeight, false);

        // For the DPI check blur and glare only in the inner ROI
        let roiSize = new cv.Rect(pt1.x, pt1.y, pt2.x - pt1.x, pt2.y - pt1.y);
        let roi = srcRef.roi(roiSize);

        // Set canvas width
        canvasRef.current.width = roiSize.cols;
        canvasRef.current.height = roiSize.rows;

        let isBlurred = checkBlur(roi);
        let hasGlare = checkGlare(roi);
        
        // Draw a rectangle (bounding box) around the detected ID

        // addLabels(srcRef, isBlurred, hasGlare, pt1)

        let id = (!isBlurred && !hasGlare) ? 4 : (isBlurred && hasGlare) ? 3 : isBlurred ? 1 : hasGlare ?? 2

        setMessage(id)
        // Draw the rectangle on the srcRef (video frame)
        // cv.rectangle(srcRef, new cv.Point(pt1.x, pt1.y), new cv.Point(pt2.x, pt2.y), color, thickness);
        // Draw
        cv.imshow(canvasRef.current, roi);
        const ctxText = canvasRef.current.getContext('2d');
        requestAnimationFrame(() => {
            drawText(ctxText, message.current.message, message.current.error);
        });

        srcRef.delete();
        roi.delete();
    };

    function checkGlare(src) {
        return GlareDetection(cv, src, null, 1000);
    }

    function checkBlur(src) {
        return BlurDetection(cv, src, null, 400);
    }

    async function extractAllText(src) {
        return DetectText(cv, Tesseract, src);
    }

    async function takePicture() {

        setCheckingIsValidImage(true);
        reset();

        // Check if video is ready before capturing frame
        const video = liveVideoRef.current;
        if (!video || video.width === 0 || video.height === 0) {
            console.error("Video dimensions are not valid.");
            return;
        }

        let videoWidth = video.width;
        let videoHeight = video.height;

        let srcRef = new cv.Mat(videoHeight, videoWidth, cv.CV_8UC4);

        // Take the current frame from the video element
        let capRef = new cv.VideoCapture(liveVideoRef.current);
        capRef.read(srcRef);
    
        // Calculate the same bounding area (ROI) as in live detection
        const { pt1, pt2 } = CalculateBoundingArea(cv, srcRef, videoWidth, videoHeight, false);
        let roiSize = new cv.Rect(pt1.x, pt1.y, pt2.x - pt1.x, pt2.y - pt1.y);
        let roi = srcRef.roi(roiSize);
    
        // Display the image captured on the canvas (if needed)
        canvasRef.current.width = roiSize.cols;
        canvasRef.current.height = roiSize.rows;
        cv.imshow(canvasRef.current, roi);
    
        // Run the same blur and glare detection as in live feed
        const srcHasGlare = checkGlare(roi);
        const srcIsBlurred = checkBlur(roi);
        const srcText = await extractAllText(roi);
    
        // Check for errors and update the state
        if (srcHasGlare) {
            setImageErrors((prev) => [
                ...prev,
                'The document has too much glare. Disable your flashlight, and make sure there are not any other reflection on the document.',
            ]);
        }
    
        if (srcIsBlurred) {
            setImageErrors((prev) => [
                ...prev, 
                'The image is too blurry. Make sure to be in a well-lit environment.'
            ]);
        }
    
        if (!srcText) {
            setImageErrors((prev) => [
                ...prev, 
                'Your document seems to be unreadable. Please try again.'
            ]);
        }
    
        stopCamera();

        // Set the final image data and validation status
        setImageData(canvasRef.current.toDataURL('image/jpeg', 1));
        setImageText(srcText);
        let validationSucceed = !srcHasGlare && !srcIsBlurred && srcText;
        setIsValidImage(validationSucceed);
        setCheckingIsValidImage(false);
    
        // Cleanup
        srcRef.delete();
        roi.delete();
    }
    
    const getCameraPermission = async () => {
        //get video permissions and then stream the result media stream to the videoSrc variable
        if ('MediaRecorder' in window) {
            setGettingCameraPermission(true);

            const videoConstraints = {
                audio: false,
                video: {
                    facingMode: 'environment',
                    pan: false,
                    tilt: false,
                    zoom: false,
                },
            };

            try {
                const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints);

                if (!!videoStream) {
                    //set videostream to live feed player
                    setFailedCameraAccess(false);
                    setNoAvailableCamera(false);
                    stream.current = videoStream;
                    liveVideoRef.current.srcObject = videoStream;

                    await liveVideoRef.current.play().catch((err) => {
                        console.error("Failed to play video:", err); // Handle autoplay issues
                    });
                    
                    setLoading(false);
                    setGettingCameraPermission(false);
                }
            } catch (err) {
                setLoading(false);
                setGettingCameraPermission(false);
                setFailedCameraAccess(true);
                setNoAvailableCamera(false);
                console.error(err);
            }
        } else {
            setLoading(false);
            setGettingCameraPermission(false);
            setFailedCameraAccess(false);
            setNoAvailableCamera(true);
        }
    };

    const stopCamera = () => {
        if (!!stream.current) {
            stream.current.getTracks().forEach(function (track) {
                track.stop();
            });
        }
    };

    const reset = () => {
        clearInterval(intervalRef.current);
        setImageData(null);
        setImageText(null);
        setIsValidImage(false);
        setImageErrors([]);
    };

    React.useEffect(() => {
        if (openCvLoaded && liveVideoRef.current) {
            getCameraPermission();
        }
    }, [openCvLoaded, liveVideoRef.current]);

    React.useEffect(() => {
        setLoading(true);
        return () => {
            reset();
            stopCamera();
            setLoading(true);
            setFailedCameraAccess(false);
            setNoAvailableCamera(false);
            setGettingCameraPermission(false);
        };
    }, []);

    const retry = () => {
        setLoading(true);
        reset();
        stopCamera();
        getCameraPermission();
    };

    // function changeCamera() {
    //     if (cameraType === 'user') {
    //         setCameraType('environment');
    //     } else {
    //         setCameraType('user');
    //     }
    //     retry();
    // }

    return (
        <Box>
            
            <Stack 
                alignItems={'center'} 
                justifyContent={'center'} 
                spacing={2}
            >
                {!!imageData && (
                    <Box sx={{ width: '100%' }}>
                        {checkingIsValidImage && (
                            <Typography variant="h4" align="center">
                                Validating image...
                            </Typography>
                        )}
                        {isValidImage && !checkingIsValidImage && (
                            <Stack direction={"row"} spacing={2}>
                                <Button variant="outlined" color="warning" onClick={retry} sx={{ width: '100%', paddingX: 4 }}>
                                    Take Again
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        continueCallbackFunction();
                                        if (!stopFlag) {
                                            retry();
                                        }
                                    }}
                                    sx={{ width: '100%', paddingX: 4 }}
                                >
                                    Save
                                </Button>
                            </Stack>
                        )}
                        {!isValidImage && !checkingIsValidImage && (
                            <Box>
                                <Box sx={{}}>
                                    {imageErrors.length > 0
                                        ? imageErrors.map((error, index) => (
                                              <Typography key={index} variant="h6" align="center" >
                                                  <Typography color={'red'} display={'inline-block'}>
                                                      *
                                                  </Typography>{' '}
                                                  {error}
                                              </Typography>
                                          ))
                                        : null}
                                </Box>
                                <Button variant="outlined" color="warning" onClick={retry} sx={{ width: '100%', paddingX: 4 }}>
                                    Take Again
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}

                    <Box 
                        component={'video'} 
                        id="documentVideo" 
                        ref={liveVideoRef} 
                        onLoadedMetadata={handleVideoLoaded} 
                        autoPlay 
                        playsInline 
                        muted
                        hidden={true} 
                    />
                    <Box
                        id={'documentRoi'}
                        component={'canvas'}
                        ref={canvasRef}
                        sx={{
                            position: 'relative',
                            top: 0,
                            width: { xs: '90vw', md: '40vw' }, // 90% for mobile, 40% for desktop
                            height: { xs: 'calc(90vw * 2 / 3)', md: 'calc(40vw * 2 / 3)' }, // Maintain 2:3 aspect ratio
                            maxWidth: '640px', // Optional: Limit max width for larger screens
                            maxHeight: '480px', // Optional: Limit max height
                            display: loading || gettingCameraPermission || failedCameraAccess || noAvailableCamera ? 'none' : 'block',
                        }}
                        hidden={imageData}
                    />
            </Stack>

            {!imageData && (loading || gettingCameraPermission || failedCameraAccess || noAvailableCamera) && (
                <Grid container direction="column" justifyItems={'center'} alignItems="center" spacing={2}>
                    <Grid>
                        {/** Camera Content */}
                        <Card elevation={5} sx={{
                        width: {xs: "90vw", md: "50vw"},
                        height: "480",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                         }}>
                            <CardContent>
                                {((loading || gettingCameraPermission || failedCameraAccess || noAvailableCamera) &&
                                    (loading ? loadingChildComponent : null)) ||
                                    (gettingCameraPermission ? gettingCameraPermissionChild : null) ||
                                    (noAvailableCamera ? noAvailableCameraChildComponent : null) ||
                                    (failedCameraAccess ? failedCameraAccessChildComponent : null)}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}



            <Box sx={{ width: '100%', maxWidth: '100%' }}>
                {!imageData && !loading && !gettingCameraPermission && !failedCameraAccess && !noAvailableCamera && (
                    <Stack direction="row" alignItems="center" spacing={2} sx={{ width: '100%', justifyContent: 'center' }}>
                        {/* <ChangeCircleOutlinedIcon sx={{ }} fontSize="large" onClick={() => { setImageData(null) }} display={imageData ? "" : "none"}/> */}
                        <IconButton onClick={takePicture}>
                            <LensTwoToneIcon sx={{ fontSize: '4rem !important' }} >
                                <Typography variant="h6" sx={{ }}>
                                    Capture
                                </Typography>
                            </LensTwoToneIcon>
                        </IconButton>

                        {/* <CameraswitchIcon onClick={changeCamera} sx={{  }} fontSize="large" /> */}
                    </Stack>
                )}
                
            </Box>
        </Box>
    );
};

export default DocumentCheckCamera;
