import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ScrollToTop from '../../_helpers/scrollToTop';
import AcceptableUse from '../../Pages/Policies/AcceptableUse';
import CookiePolicy from '../../Pages/Policies/CookiePolicy';
import PrivacyPolicy from '../../Pages/Policies/Privacy';
import TermsOfService from '../../Pages/Policies/TermsOfService';
import { CaseStudies } from '../../Pages/Public/CaseStudies/CaseStudiesHome/main';
import Contact from '../../Pages/Public/Contact';
import PublicSiteHome from '../../Pages/Public/Home';
import PasswordResetPage from '../../Pages/ServicePages/PasswordReset/main';
import CustomIdentityVerification from '../../Pages/WorkspaceConsolePage/Home/Profile/IdentityCheck/CustomIdentityVerification';
import Layout from '../_publicsite/common/Layout';
import CookieConsent from '../_publicsite/elements/cookie/main';
import IdvCheck from '../DocumentCheck/DocumentCheck';
import { useConnectedMessage } from '../_shared/elements/message/MessageProvider';
import Tester from '../../tester';
import DocumentVerificationMobile from '../../Pages/ServicePages/DocumentVerificationMobile/main';

const Root = (props) => {
    const Corporate = React.lazy(() => import('./Lazy/corporate/mainRoutes'));

    const WorkSpace = React.lazy(() => import('./Lazy/workspace/mainRoutes'));

    const { toggleShow, setData }                 = useConnectedMessage(); 

    const location = useLocation();

    React.useEffect(() =>{

        const handleOnline = () => {  
            if(location.pathname.includes("corporate") || location.pathname.includes("workspace")){          
                toggleShow(true);
                setData({
                    title: "You are online.",
                    subtitle: "Welcome back!",
                    buttonLabelDismiss: "Dismiss"
                })
            }
        };
        const handleOffline = () => {
            if(location.pathname.includes("corporate") || location.pathname.includes("workspace")){
                toggleShow(true);
                setData({
                  title: "You are offline.",
                  subtitle: "Please connect to the internet to continue working.",
                })
            }
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };

    },[location]);

    return (
        <Routes>
            {/**Corporate Routes */}
            <Route
                path="corporate/*"
                element={
                    <React.Suspense fallback={<div>Hey taking my time... </div>}>
                        <Corporate />
                    </React.Suspense>
                }
            />
            {/**Workspace Routes */}
            <Route
                path="workspace/*"
                element={
                    <React.Suspense fallback={<div>Hey taking my time... </div>}>
                        <WorkSpace />
                    </React.Suspense>
                }
            />
            <Route exact path="phone-verification/*" element={<IdvCheck />} />
            {/**Register Page Universal*/}
            {/* <Route path="/register/:companyID?" element={<RegisterPageCorporateFormik/>} /> */}
            {/**Public Pages*/}
            {/* <Route exact path="/reset/password/:tempKey?/:companyID?"    element={<PasswordResetPage/>} />
                <Route exact path="/identity/:sessionID?"                    element={<IDVCamera/>} />
                <Route exact path="/expired"                                 element={<ExpiredSession/>} />
                <Route exact path="/join-us/contact-form"                    element={<ContactUsForAJob/>} /> */}
            <Route
                element={
                    <>
                        <CookieConsent />
                        <ScrollToTop />
                        <Layout />
                    </>
                }
            >
                <Route index element={<PublicSiteHome />} />
                <Route exact path="contact" element={<Contact />} />
                <Route exact path="case-study/:id" element={<CaseStudies />} />
                <Route exact path="/reset/password/:tempKey?/:companyID?" element={<PasswordResetPage />} />
                {/* <Route exact path="/blog/:id?" element={<Blog />} /> */}
                {/* <Route exact path="/linkedin" element={<LinkedInCallback />} /> */}
                <Route exact path="/policies/privacy" element={<PrivacyPolicy />} />
                <Route exact path="/policies/terms" element={<TermsOfService />} />
                <Route exact path="/policies/cookiepolicy" element={<CookiePolicy />} />
                <Route exact path="/policies/acceptable-use-policy" element={<AcceptableUse />} />
                <Route exact path="/tester" element={<Tester />} />
            </Route>

            {/** Tester Route */}
            {/* <Route exact path="/test"                                 element={TestComponent} /> */}
            {/**Fall Back Path */}
            <Route exact path="/document/verification/:tempKey?/:companyID?" element={<DocumentVerificationMobile />} />
            <Route path="/" element={<Navigate to="/" replace />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default Root;
