import React from "react";
import LottieCamera from "./_components/LoadingAnimations/LottieCamera";
import { Box, Card, Grid2 as Grid, CardContent, Stack, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { userActions } from "./_redux/_actions";


const Tester = () => {

    const dispatch = useDispatch();

    const fetchSession = () => {
        
    }



    return (
        <Box
            sx={{
                height: '100vh',
            }}
        >
            <Grid
                container
                display="flex"
                justifyContent={'center'}
                alignItems={'center'}
                className="content live-gradient"
            >
                <Grid>
                    <>
                        <Card
                            elevation={5}
                            sx={{
                                width: 640,
                                height: 480,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <Grid
                                container
                                display="flex"
                                justifyContent={'center'}
                                alignItems={'center'}   
                                spacing={2}
                            >
                                <Grid size={12}>
                                    <Typography variant="body1" color="textSecondary" textAlign={'center'}>
                                        Please, allow access to your camera in your browser.
                                    </Typography>
                                </Grid>
                                <Grid display={"flex"} size={12} justifyContent={"center"}>
                                    <LottieCamera height={250} width={250} />
                                </Grid>
                            </Grid>
                        </Card>
                    </>

                </Grid>
            </Grid>
        </Box>
    )


}


export default Tester