import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../_redux/_actions";
import ServiceLayout from "../../../_layouts/Services/ServiceLayout";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { serviceRequestsService } from "../../../_services/servicerequests.service";
import moment from "moment";
import { Box, CardContent, Typography } from "@mui/material";
import DocumentCheck from "../../../_components/DocumentCheck/DocumentCheck";



const DocumentVerificationMobile = () => {


    const [completed, setCompleted] = React.useState(false);
    const [expired, setExpired] = React.useState(false);
    const [serviceRequestData, setServiceRequestData] = React.useState(null);

    const loggedUser = useSelector((state) => state.authentication.user);

    const params = useParams();

    const { tempKey, companyID } = params;

    const dispatch = useDispatch();

    // Fetch the tempKey data.
    const fetchResetRequest = async () => {
        try {
            const findServiceRequest = await serviceRequestsService.findOne(true, { tempKey });
            console.log(findServiceRequest)
            // Dispatch User data.
            dispatch(userActions.login(findServiceRequest.data.userDetails))
            setServiceRequestData(findServiceRequest.data);

            if (findServiceRequest?.data?.completed === true) {
                setCompleted(true);
            }
            if (moment().unix() > findServiceRequest.expirationDateTime) {
                setExpired(true);
            }


        } catch (err) {
            console.log(err)
            setServiceRequestData(null);
            toast.error("Error fetching your ID ",)
        }

    }


    // Log out on Mount and when unMounting
    React.useEffect(() => {
        dispatch(userActions.logout());
        fetchResetRequest();
        return () => {
            dispatch(userActions.logout());
        }
    }, [])


    return (
        <ServiceLayout>
            <CardContent
                sx={{
                    height:  { xs: "50vh", md: "80vh" },
                    width: { xs: "90vw", md: "60vw" },
                    overflow: "auto",
                }}
            >
                {!loggedUser.biometricVerificationCompleted &&
                    <Typography>
                        Please complete Biometric Check first.
                    </Typography>
                }
                {loggedUser.documentVerificationCompleted &&
                    <Typography>
                        Document Verification Completed.
                    </Typography>
                }
                {loggedUser.biometricVerificationCompleted && !expired && !completed && !loggedUser.documentVerificationCompleted &&
                    <DocumentCheck serviceRequestData={serviceRequestData} />
                }
                {expired &&
                    <Typography>
                        Session expired.
                    </Typography>
                }
                {completed &&
                    <Typography>
                        Session already completed.
                    </Typography>
                }
            </CardContent>
        </ServiceLayout>
    )
}



export default DocumentVerificationMobile