const path = require("path");

export const NUM_KEYPOINTS = 468;
export const NUM_IRIS_KEYPOINTS = 5;
export const GREEN = '#32EEDB';
export const RED = '#FF2C35';
export const BLUE = '#157AB3';

export function isiOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAndroid() {
    return /Android/i.test(navigator.userAgent);
}

export function isMobile() {
    return isAndroid() || isiOS();
}

export const LABEL_TO_COLOR = {
    lips: '#E0E0E0',
    leftEye: '#30FF30',
    leftEyebrow: '#30FF30',
    leftIris: '#30FF30',
    rightEye: '#FF3030',
    rightEyebrow: '#FF3030',
    rightIris: '#FF3030',
    faceOval: '#E0E0E0',
};



const textOptions = {
    fontFamily: 'Consolas',
    fontSize: 14,
};

const gutterSize = 12;


export const drawText = (ctx, text, error = false) => {
    drawBorderedText(
        ctx, 
        text, 
        {
            ...textOptions,
            alignment: 'top',
            justification: 'left',
            offsetX: gutterSize,
            offsetY: gutterSize
        },
        error // Pass error here
    );
}

const drawBorderedText = (ctx, text, options = {}, error = false) => {
    const {
        fontFamily,
        fontSize,
        offsetX,
        offsetY,
    } = { ...borderdTextDefaultOptions, ...options };

    // Set text color based on error state
    const textColor = error ? 'red' : 'white';
    
    // Save the context state
    ctx.save();
    
    // Set font
    ctx.font = `${fontSize}px ${fontFamily}`;
    const textMetrics = ctx.measureText(text); // Measure text to determine background size
    const padding = 10; // Adjust padding around the text if needed

    // Calculate the width and height for the background rectangle
    const backgroundWidth = textMetrics.width + padding * 2;
    const backgroundHeight = fontSize + padding * 2;

    // Draw black background rectangle with opacity
    ctx.fillStyle = 'rgba(0, 0, 0, 0.7)';
    ctx.fillRect(offsetX - padding, offsetY - padding, backgroundWidth, backgroundHeight);

    // Set text styles
    ctx.fillStyle = textColor;
    ctx.textAlign = 'left';
    ctx.textBaseline = 'top';

    // Draw the text
    ctx.fillText(text, offsetX, offsetY); 

    // Restore the context state
    ctx.restore();
};

const borderdTextDefaultOptions = {
    alignment: 'top',
    borderColor: '#000',
    borderSize: 1,
    fontColor: '#000',
    fontFamily: 'Arial',
    fontSize: 12,
    justification: 'left',
    lineDash: [],
    padding: 0,
    offsetX: 0,
    offsetY: 0
};

