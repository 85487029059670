import React from "react";
import { Backdrop, Button, Snackbar, Stack, Typography } from "@mui/material";
import { useConnectedMessage } from "./MessageProvider";

const Message = () => {

    const { show, data, toggleShow, setData, secondaryAction } = useConnectedMessage(); // Use the context

    const handleClick = () => {
        toggleShow(false);
        setData({});
    }

    return (
        <>
            <Snackbar
                open={show}
                id="connected-snackbar"
                message={

                    <Stack sx={{ width: 500 }}>
                        <Typography variant="h6" >
                            {data.title}
                        </Typography>

                        <Typography variant="subtitle1">
                            {data.subtitle}
                        </Typography>
                    </Stack>


                }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                action={
                    <Stack direction="row" spacing={1}>
                        {!!data.buttonLabelDismiss &&
                            <Button
                                color="inherit"
                                size="small"
                                onClick={handleClick}
                            >
                                {data.buttonLabelDismiss}
                            </Button>
                        }
                        {!!secondaryAction &&
                        <Button
                            color="inherit"
                            size="small"
                            onClick={handleClick}
                        >
                            {secondaryAction.label}
                        </Button>
                        }

                    </Stack>

                }
            />

            {!data.buttonLabelDismiss &&
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={show}
                >

                </Backdrop>
            }


        </>

    );

}


export default Message;
